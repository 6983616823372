import { ImageContainer, EmptyBlock } from "./index.styled";

export function Background() {
  return (
    <ImageContainer>
      <EmptyBlock className="empty-block"></EmptyBlock>
      <img src="/38.webp" alt="Background 1" width="1200px" height="800px" />
      <img src="/2-C.webp" alt="Background 2" width="1200px" height="800px" />
      <img src="/3-C.webp" alt="Background 3" width="1200px" height="800px" />
    </ImageContainer>
  )
}