import { useState } from "react";
import { ReactComponent as Arrow } from "../../assets/arrow.svg";
import { useIsMobile } from "../../utils/hooks";
import { CatalogContainer } from "./index.styled";
import { Link } from "react-router-dom";

import styled from "styled-components";

const ArrowSpin = styled(Arrow)`
  display: inline-block;
  transition: transform 0.3s ease;
`;

const WholesaleDropdown = () => (
  <div>
    <div>
      <Link to="contact">🇺🇸 USA</Link>
    </div>
    <div>
      <a href="https://thenewways.com?utm_source=grollzcom" target="_blank">
        🌍 WORLD
      </a>
    </div>
  </div>
);

export function Catalog() {
  const isMobile = useIsMobile();
  const [showWholesaleDropdown, setShowWholesaleDropdown] = useState(false);

  const toggleWholesaleDropdown = () => {
    setShowWholesaleDropdown(!showWholesaleDropdown);
  };

  if (isMobile) {
    return (
      <CatalogContainer>
        <div>
          <a href="/catalog/2023">
            <Arrow />
            CATALOG 2024
          </a>
        </div>
        <div>
          <a href="/catalog/2022">
            <Arrow />
            CATALOG 2022-23
          </a>
        </div>

        <div onClick={toggleWholesaleDropdown}>
          <a>
            <ArrowSpin
              style={{
                transform: showWholesaleDropdown
                  ? "rotate(90deg)"
                  : "rotate(0deg)",
              }}
            />
            WHOLESALE
            {showWholesaleDropdown && <WholesaleDropdown />}
          </a>
        </div>
      </CatalogContainer>
    );
  }
  return (
    <CatalogContainer>
      <ul>
        <li>
          CATALOG
          <ul>
            <li>
              <a href="/catalog/2023">
                <Arrow />
                2024
              </a>
            </li>
            <li>
              <a href="/catalog/2022">
                <Arrow />
                2022-23
              </a>
            </li>
          </ul>
        </li>
        <li>
          WHOLESALE
          <ul>
            <li>
              <Link to="contact">
                <a href="mailto:hey@grollz.com" target="_blank">
                  <Arrow />
                  USA
                </a>
              </Link>
            </li>
            <li>
              <a
                href="https://thenewways.com?utm_source=grollzcom"
                target="_blank"
              >
                <Arrow />
                REST OF WORLD
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </CatalogContainer>
  );
}
