import styled from "styled-components";
import { ReactComponent as _Logo } from "./assets/grollz.svg";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
  scroll-snap-type: y mandatory;
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Footer = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 768px) {
    margin-bottom: unset;
    display: flex;
    flex-direction: column;
    justify-content: unset;
    align-items: unset;
  }
`;

export const Logo = styled(_Logo)`
  z-index: -2;
  height: 40vh;
  width: 100%;
  position: relative;
  max-width: 2000px;
  transition: height 0.5s ease;
  @media only screen and (max-width: 768px) {
    height: 15vh;
  }
`;

export const ClickableLogo = () => {
  return (
    <Link to="/" className="logo-link" style={{ textAlign: "center" }}>
      <div>
        <Logo style={{ height: "15vh" }} />
      </div>
    </Link>
  );
};

export const Header = styled.div`
  text-align: center;
`;
