import Home from "./pages/Home";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ContactForm from "./components/contact";
import Catalog23 from "./components/catalog23";
import Catalog22 from "./components/catalog22";
import Catalog23IT from "./components/catalog23it";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/catalog/champs23" element={<Catalog23IT />} />
        <Route path="/catalog/2022" element={<Catalog22 />} />
        <Route path="/catalog/2023" element={<Catalog23IT />} />
        <Route path="/catalog/intertabac23" element={<Catalog23IT />} />
      </Routes>
    </Router>
  );
}

export default App;
