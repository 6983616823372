/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 booklet.gltf
*/

import React, { useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useIsMobile } from "../../utils/hooks";

export function Model(props) {
  const isMobile = useIsMobile();
  const { nodes, materials } = useGLTF("/booklet.glb");

  const groupRef = useRef();

  // Time state to control the sine wave
  const [time, setTime] = useState(0);

  // Adjust these values to control the rotation and position of the model
  const rotationZ = Math.PI / 2; // 45 degrees rotation on the X axis
  const rotationY = Math.PI; // 45 degrees rotation on the Y axis

  useFrame((state, delta) => {
    if (groupRef.current) {
      groupRef.current.rotation.x += 0.01; // Adjust the value added to control the rotation speed
      groupRef.current.rotation.y += 0.01;

      // Update time for sine wave
      setTime((prevTime) => prevTime + delta * 0.5);

      // Calculate the new position using sine wave
      const radius = isMobile ? 1 : 2; // Adjust the radius to control the size of the circular movement
      const posX = radius * Math.sin(time);
      const posY = radius * Math.cos(time);

      groupRef.current.position.x = posX;
      groupRef.current.position.y = posY;
    }
  });

  return (
    <group
      ref={groupRef}
      {...props}
      dispose={null}
      rotation={[0, rotationY, rotationZ]}
      position={[-3.3, -0.7, 0]}
      scale={[0.3, 0.3, 0.3]}
    >
      <mesh
        geometry={nodes.Booklet_Tray.geometry}
        material={materials.phongE1}
      />
      <mesh
        geometry={
          nodes.Corpo_CartineCorpo_ChiusoPacchettoPacchetto_Chiuso.geometry
        }
        material={materials.phongE2}
      />
      <mesh
        geometry={
          nodes.Corpo_ChiusoPacchettoPacchetto_ChiusoInternoCartine.geometry
        }
        material={materials.phongE6}
      />
      <mesh
        geometry={nodes.PacchettoPacchetto_ChiusoTop_Cartine1.geometry}
        material={materials.phongE4}
      />
      <mesh
        geometry={nodes.Pacchetto_ChiusoCartine1.geometry}
        material={materials.phongE5}
      />
      <mesh geometry={nodes.TIP.geometry} material={materials.phongE3} />
    </group>
  );
}

useGLTF.preload("/booklet.glb");
