/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useState, useRef, useCallback } from "react";
import Marquee from "react-fast-marquee";
import { FaPlay, FaPause, FaBackward, FaForward } from "react-icons/fa";
import { formatTime } from "../../utils";
import songs from "../../constants/songs";
import { Controls, PlayerContainer, Track, ControlsButton, PauseButton, Time, Input } from "./index.styled";
import { useAtom } from "jotai";
import { Playing } from "../../utils/atoms";


export function Player() {
  const audioRef = useRef();
  const [isPlaying, setPlaying] = useAtom(Playing);

  const [songIndex, setSongIndex] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const playNext = () => {
    setSongIndex((songIndex + 1) % songs.length);
    setCurrentTime(0);
    audioRef.current.currentTime = 0;
    if (isPlaying) {
      audioRef.current.pause();
      setTimeout(() => {
        audioRef.current.play();
      }, 100);
    }
  };

  const playPrevious = () => {
    setSongIndex((songIndex - 1 + songs.length) % songs.length);
    setCurrentTime(0);
    audioRef.current.currentTime = 0;
    if (isPlaying) {
      audioRef.current.pause();
      setTimeout(() => {
        audioRef.current.play();
      }, 100);
    }
  };

  const togglePlay = useCallback(() => {
    const audio = audioRef.current;
    if (audio.paused) {
      audio.play();
      setPlaying(true);
    } else {
      audio.pause();
      setPlaying(false);
    }
  }, [setPlaying]);

  return (
    <PlayerContainer>
      <audio
        ref={audioRef}
        preload="none"
        src={songs[songIndex].url}
        onTimeUpdate={() => setCurrentTime(audioRef.current.currentTime)}
      />
      <Track>
        <Marquee speed={35}>
          {songs[songIndex].name}
        </Marquee>
      </Track>
      <Controls>
        <ControlsButton aria-label="play previous">
          <FaBackward onClick={playPrevious} />
        </ControlsButton>
        <PauseButton aria-label="toggle play" onClick={togglePlay}>
          {isPlaying ? <FaPause /> : <FaPlay />}
        </PauseButton>
        <ControlsButton aria-label="play next">
          <FaForward onClick={playNext} />
        </ControlsButton>
      </Controls>
      <Time>{`(${formatTime(currentTime)} / ${formatTime(songs[songIndex].duration)})`}</Time>
      <Input
        aria-label="seek bar"
        type="range"
        min="0"
        max={audioRef.current && audioRef.current.duration ? audioRef.current.duration : "100"}
        value={currentTime}
        onChange={(e) => {
          setCurrentTime(e.target.value);
          audioRef.current.currentTime = e.target.value;
        }}
      />
    </PlayerContainer>
  );
}
