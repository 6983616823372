import styled from "styled-components";

export const CatalogContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    margin-top: 4rem;
  }
  svg {
    transition: all 0.2s ease;
    width: 12px;
  }
  > ul > li {
    margin-bottom: 10px;
  }
  ul {
    list-style-type: none;
    li {
      margin-left: 0px;
    }
    ul {
      margin-left: 0px;
    }
  }
`;
