import React, { useState, useEffect } from "react";
import { Wrapper, Logo, Header, ClickableLogo } from "../../index.styled";
import Footer from "../footer-v2";
const Catalog23IT = () => {
  return (
    <Wrapper
      style={{
        position: "relative",
        maxWidth: "1500px",
        margin: "0 auto",
      }}
    >
      <ClickableLogo />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "0rem",
        }}
      >
        <h3>Catalog 2024</h3>
        <iframe
          src="https://player.flipsnack.com?hash=RUE1QTdEODhCN0EreHVqbGR6bzAydg=="
          width="100%"
          height="580"
          seamless="seamless"
          scrolling="no"
          frameBorder="0"
          allowFullScreen
          allow="autoplay; clipboard-read; clipboard-write"
        ></iframe>
      </div>
      <Footer />
    </Wrapper>
  );
};

export default Catalog23IT;
