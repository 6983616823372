import React from 'react';
import { CSSTransition } from 'react-transition-group';
import './index.css';

const SlideUpTransition = (WrappedComponent) => {
  return (props) => {
    return (
      <CSSTransition
        in={true}
        appear={true}
        timeout={500}
        classNames="slide-up"
      >
        <WrappedComponent {...props} />
      </CSSTransition>
    );
  };
};

export default SlideUpTransition;