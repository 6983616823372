import React from "react";
import "./index.css";
import { FaInstagram, FaRegEnvelope } from "react-icons/fa";

const Footer = () => {
  return (
    <>
      {/* <div className="footer-top-line"></div> */}
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-section">
            <h3>Contact</h3>

            <div>
              <a href="mailto:hey@grollz.com">
                <FaRegEnvelope /> &nbsp; hey@grollz.com
              </a>
            </div>

            <div>
              <a
                href="https://www.instagram.com/grollz"
                target="_blank"
                rel="noreferrer"
                style={{ display: "flex", alignItems: "center" }}
              >
                <FaInstagram />
                &nbsp; @grollz
              </a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <p>&copy; G-Rollz 2023. All rights reserved.</p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
