import styled from "styled-components";

export const ImageContainer = styled.div`
  position: relative;
  z-index: 0;
  img {
    display: block;
    width: 100%;
    height: auto;
    scroll-snap-align: start;
    object-fit: cover;
  }
  @media only screen and (max-width: 768px) {
    img {
      display: block;
      width: 100vw;
      height: 100vh;
      scroll-snap-align: start;
      object-fit: cover;
    }
  }
`;

export const EmptyBlock = styled.div`
  height: 100vh;
  scroll-snap-align: start;
`;
