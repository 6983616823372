import styled from "styled-components";

export const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-top: 1rem;
  width: 12rem;
  padding: 20px;
`;

export const Track = styled.div`
  width: 100%;
  font-family: monospace;
  font-size: 10px;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ControlsButton = styled.button`
  margin: 0 10px;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => (theme.type === "dark" ? "white" : "black")};
  transition: all 0.2s ease;
  &:hover {
    background-color: transparent;
    cursor: pointer;
    transform: scale(1.2);
  }
`;

export const PauseButton = styled.button`
  margin: 0 10px;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  color: ${({ theme }) => (theme.type === "dark" ? "white" : "black")};
  background-color: rgba(0, 0, 0, 0);
  transition: all 0.2s ease;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0);
    transform: scale(1.2);
  }
`;

export const Time = styled.div`
  margin-bottom: 0.3rem;
`;

export const Input = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  background: var(--text-color);
  border-radius: 5px;
  outline: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background: var(--background-color);
    border: 1px solid var(--text-color);
    border-radius: 50%;
    cursor: pointer;
    margin-top: 2px;
    transition: all 0.2s ease;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: var(--background-color);
    border: 1px solid var(--text-color);
    border-radius: 50%;
    cursor: pointer;
  }

  &::-ms-thumb {
    width: 20px;
    height: 20px;
    background: var(--background-color);
    border: 1px solid var(--text-color);
    border-radius: 50%;
    cursor: pointer;
  }

  &:hover::-webkit-slider-thumb {
    width: 25px;
    height: 25px;
  }

  &:hover::-moz-range-thumb {
    width: 25px;
    height: 25px;
  }

  &:hover::-ms-thumb {
    width: 25px;
    height: 25px;
  }
`;
