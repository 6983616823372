import { Canvas } from "@react-three/fiber";
import { Model } from "./model";
import { CanvasContainer } from "./index.styled";

export function Booklet() {
  return (
    <CanvasContainer>
      <Canvas camera={{ position: [0, 0, 5] }}>
        <ambientLight intensity="1.0" />
        <pointLight position={[10, 10, 10]} />
        <Model />
      </Canvas>
    </CanvasContainer>
  );
}
