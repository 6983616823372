import styled from "styled-components";

export const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 3rem;
  @media only screen and (max-width: 768px) {
    margin-top: 1rem;
  }
`;

export const Instagram = styled.a`
  margin-top: 1rem;
  display: flex;
  align-items: center;
  font-size: 15px;
  svg {
    margin-right: 3px;
  }
`;

export const Spotify = styled.a`
  display: flex;
  align-items: center;
  font-size: 15px;
  svg {
    margin-right: 3px;
  }
`;

export const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -3;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: ${({ background }) => `url(${background})`};
`;
