const songs = [
  {
    name: "4kjell_g_rolla.mp3",
    url: "https://res.cloudinary.com/dzgsn2mtu/video/upload/v1682290560/4kjell_g_rolla_lrdc7c.mp3",
    duration: 2522,
  },
  {
    name: "no_more_pain_4ever_beloved_shoutout@onzkereguy@nmphassan_lostmix.mp3",
    url: "https://res.cloudinary.com/dzgsn2mtu/video/upload/v1682290537/no_more_pain_4ever_beloved_shoutout_onzkereguy_nmphassan_lostmix_jpxpjk.mp3",
    duration: 565,
  },
  {
    name: "stonedmix_april2022.mp3",
    url: "https://res.cloudinary.com/dzgsn2mtu/video/upload/v1682290562/stonedmix_april2022_ng3ejv.mp3",
    duration: 1832,
  },
  // Add more songs as needed
];

export default songs;
