import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";
import { Wrapper, Logo, Header, ClickableLogo } from "../../index.styled";
import "./index.css";
import SlideUpTransition from "../slideup";
import { PulseLoader } from "react-spinners";
import Footer from "../footer";
import { ReactComponent as Arrow } from "../../assets/arrow.svg";

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    document.body.classList.remove("dark-theme");

    // Clean up effect when component is unmounted
    return () => {
      document.body.classList.remove("dark-theme");
    };
  }, []);

  const onSubmit = async (data) => {
    const { name, email, message } = data; //companyName, phone
    setIsSubmitting(true);
    try {
      const templateParams = {
        // companyName,
        name,
        email,
        // phone,
        message,
      };
      await emailjs.send(
        "service_uo2wa7o",
        "template_rsprldm",
        templateParams,
        "z9gAeRmDZG9nNGv8P"
      );
      reset();
      setIsSubmitted(true);
    } catch (e) {
      console.log(e);
    } finally {
      setIsSubmitting(false);
    }
  }; // className="imageContainer"

  return (
    <Wrapper
      style={{
        position: "relative",
        maxWidth: "1500px",
        margin: "0 auto",
      }}
    >
      <ClickableLogo />
      <div className="wrapper">
        {isSubmitted ? (
          <div className="contactForm">
            Thank you for your request! We will get back to you shortly.
            <Link to="/">
              <button
                className="submit-btn"
                type="submit"
                style={{ marginTop: "10px" }}
              >
                <Arrow style={{ fill: "white", height: "0.7rem" }} /> Home
              </button>
            </Link>
          </div>
        ) : (
          <div className="contactForm">
            <h1>G-Rollz B2B [USA]</h1>
            <p style={{ fontWeight: "100" }}>
              For inquiries about our wholesale and B2B offerings in the US,
              please complete the form below. Our team will get back to you
              shortly.
            </p>
            <form
              id="contact-form"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
            >
              <div className="formRow">
                {/* <input
            type="text"
            name="companyName"
            {...register('companyName', { required: 'Please enter your company name', maxLength: 50 })}
            className="formInput"
            placeholder="Company Name"
          />
          {errors.companyName && <span className="errorMessage">{errors.companyName.message}</span>} */}
                <input
                  type="text"
                  name="name"
                  {...register("name", {
                    required: "Please enter your name",
                    maxLength: 30,
                  })}
                  className="formInput"
                  placeholder="Your Name"
                />
                {errors.name && (
                  <span className="errorMessage">{errors.name.message}</span>
                )}
              </div>
              <div className="formRow">
                <input
                  type="email"
                  name="email"
                  {...register("email", {
                    required: "Please enter a valid email address",
                    pattern:
                      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  })}
                  className="formInput"
                  placeholder="Email address"
                />
                {errors.email && (
                  <span className="errorMessage">{errors.email.message}</span>
                )}
              </div>
              {/* <div className="formRow"><input
            type="text"
            name="phone"
            {...register('phone', { required: 'Please enter your phone number', pattern: /^[0-9+\s()-]+$/ })}
            className="formInput"
            placeholder="Phone"
          />
          {errors.phone && <span className="errorMessage">{errors.phone.message}</span>}</div> */}
              <div className="formRow">
                <textarea
                  rows={3}
                  name="message"
                  {...register("message", {
                    required: "Please enter a message",
                  })}
                  className="formInput"
                  placeholder="Message"
                />
                {errors.message && (
                  <span className="errorMessage">{errors.message.message}</span>
                )}
              </div>
              <button
                className="submit-btn"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? <PulseLoader color="white" /> : "Submit"}
              </button>
            </form>
          </div>
        )}
        <div className="imageContainer">
          <img src="/grt.webp" alt="Your image description" />
        </div>
      </div>
      <Footer />
    </Wrapper>
  );
};

export default SlideUpTransition(ContactForm);
