import { useEffect, useCallback, useState } from "react";
import { ThemeProvider } from "styled-components";
import { useAtom, useAtomValue } from "jotai";
import { Booklet } from "../components/booklet";
import { Player } from "../components/player";
import { Social } from "../components/social";
import { Background } from "../components/background";
import { Catalog } from "../components/catalog";
import { useIsMobile } from "../utils/hooks";
import { Playing, FirstBlockVisible, Theme, SoicalHover } from "../utils/atoms";
import { Wrapper, Footer, Logo, Header } from "../index.styled";
import SlideUpTransition from "../components/slideup";

function Home() {
  const isMobile = useIsMobile();
  const [isPlaying] = useAtom(Playing);
  const [isFirstBlockVisible, setFirstBlockVisible] =
    useAtom(FirstBlockVisible);
  const [theme, setTheme] = useAtom(Theme);
  const isSocailHover = useAtomValue(SoicalHover);

  const [appHeight, setAppHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateHeight = () => {
      setAppHeight(window.innerHeight);
    };

    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.body.classList.toggle("dark-theme", theme === "dark");
  }, [theme]);

  useEffect(() => {
    if (
      isSocailHover ||
      !isFirstBlockVisible ||
      (isPlaying && isFirstBlockVisible)
    ) {
      setTheme("dark");
    } else {
      setTheme("light");
    }
  }, [isFirstBlockVisible, setTheme, isPlaying, isSocailHover]);

  const onFirstBlockVisible = useCallback(() => {
    setFirstBlockVisible(true);
  }, [setFirstBlockVisible]);

  const onFirstBlockHidden = useCallback(() => {
    setFirstBlockVisible(false);
  }, [setFirstBlockVisible]);

  const handleIntersection = useCallback(
    (entries) => {
      if (entries[0].isIntersecting) {
        onFirstBlockVisible();
      } else {
        onFirstBlockHidden();
      }
    },
    [onFirstBlockHidden, onFirstBlockVisible]
  );

  useEffect(() => {
    const hiddenElement = document.querySelector(".empty-block");
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.8,
    });
    if (hiddenElement) {
      observer.observe(hiddenElement);
    }
    return () => {
      if (hiddenElement) {
        observer.unobserve(hiddenElement);
      }
    };
  }, [handleIntersection, isPlaying]);

  return (
    <ThemeProvider theme={{ type: theme }}>
      <Wrapper style={{ minHeight: appHeight }}>
        <Booklet />
        {isMobile && (
          <>
            <Header>
              <Logo />
              <Catalog />
            </Header>
            <Footer>
              <Social />
              <Player />
            </Footer>
          </>
        )}
        {!isMobile && (
          <>
            <Header>
              <Logo />
              <Social />
            </Header>
            <Footer>
              <Catalog />
              <Player />
            </Footer>
          </>
        )}
      </Wrapper>
      <Background />
    </ThemeProvider>
  );
}

export default Home;
