import { useState } from "react";
import { useSetAtom } from "jotai";
import { FaInstagram, FaSpotify } from "react-icons/fa";
import {
  BackgroundImage,
  Instagram,
  SocialContainer,
  Spotify,
} from "./index.styled";
import { SoicalHover } from "../../utils/atoms";

export function Social() {
  const setSocialHover = useSetAtom(SoicalHover);
  const [backgroundImage, setBackgroundImage] = useState("");

  function handleMouseEnter(src) {
    setBackgroundImage(src);
    setSocialHover(true);
  }

  function handleMouseLeave() {
    setBackgroundImage("");
    setSocialHover(false);
  }

  return (
    <>
      <SocialContainer>
        <a href="mailto:hey@grollz.com">HEY@GROLLZ.COM</a>
        {/* <a href="tel:+31641163510">+31 6 41 16 35 10</a> */}
        <Instagram
          href="https://instagram.com/grollz"
          target="_blank"
          rel="noreferrer"
          onMouseEnter={() => handleMouseEnter("/63_1.webp")}
          onMouseLeave={() => handleMouseLeave()}
        >
          <FaInstagram />
          INSTAGRAM
        </Instagram>
        <Spotify
          href="https://open.spotify.com/user/313wsxpjlvhhexem23u5koajxrvi"
          target="_blank"
          rel="noreferrer"
          onMouseEnter={() => handleMouseEnter("/12_1.webp")}
          onMouseLeave={() => handleMouseLeave()}
        >
          <FaSpotify />
          SPOTIFY
        </Spotify>
      </SocialContainer>
      {backgroundImage && <BackgroundImage background={backgroundImage} />}
    </>
  );
}
